
import React from 'react'

function Compani() {
    return (
        <>

            <div className='headh1'><h1>о компании</h1></div>
            <div className='comp '>
                <div className='comp-box'>
                    <div className='comp-box-info'>
                        <div className='comp-info'>

                            <h2>компания ООО «УПАКОВКА ДЛЯ СЕМЯН»</h2>
                            <p> ООО "УПАКОВКА ДЛЯ СЕМЯН" - производственная компания, имеющая собственную полиграфию полного цикла, поэтому вы можете в кратчайшие сроки получить качественную упаковку с неповторимым дизайном. <br />В нашей команде трудятся креативные люди, профессионалы своего дела.
                            </p>
                            <p>
                                Они помогут разработать уникальный работающий продукт для каждого клиента, ведь упаковка - это не просто средство хранения семян, но и реклама, визуальный имидж компании. Упаковка разрабатывается совсем не просто. Процесс начинается с первоначального эскиза, составленного с учетом пожеланий клиента, уточняются размеры и информационное наполнение. В этот момент происходит активная работа с заказчиком. Выявляются его потребности и конкретное направление деятельности, вкусы и личное видение готовой продукции.
                                Таким образом, каждое производство упаковки из картона имеет свой индивидуальный оттенок.</p>

                            <p>       Мы работаем на территории: России, Республики Беларусь, Киргизии, Узбекистана, Казахстана и Армении.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Compani;