import React from 'react';
import './NavMob.css';
import { Link, animateScroll as scroll } from "react-scroll";


class Nav extends React.Component {
  constructor() {
    super();
    this.state = {
      ikon: 'nav_____mobile____box',
      swoubg: 'bg_____mobile',
      swoulink: 'link_____mobile',
    }
    this.showMobNav = this.showMobNav.bind(this);
  }
  showMobNav() {
    if (window.innerWidth <= 960) {
      let App = document.querySelector('.App');
      let body = document.querySelector('body');
      body.classList.toggle('noscroll');
      App.classList.toggle('noscroll');

      if (this.state.ikon === 'nav_____mobile____box') {
        this.setState({ ikon: 'nav_____mobile____box open_____nav_____mobile____box' });
        this.setState({ swoubg: 'bg_____mobile  open____bg_____mobile' });
        setTimeout(() => {
          this.setState({ swoulink: 'link_____mobile  open_____link_____mobile' });
        }, 600);
      } else {
        this.setState({ ikon: 'nav_____mobile____box' });
        this.setState({ swoubg: 'bg_____mobile' });
        this.setState({ swoulink: 'link_____mobile' });
      }
    }

  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  render() {
    return (
      <>
        <div onClick={this.showMobNav} className="nav_____mobile">
          <div className="title_____mobile">меню</div>
          <div className={this.state.ikon}  >
            <div className="cl____box"></div>
            <div className="top____box">
              <span></span>
            </div>
            <div className="center____box">
              <span className="left"></span>
              <span className="right"></span>
            </div>
            <div className="bottom____box">
              <span></span>
            </div>
          </div>
          <div className={this.state.swoubg}></div>
        </div>

        <div className={this.state.swoulink}>
          <Link
            onClick={this.showMobNav}
            to="section1"
            spy={true}
            smooth={true}
            delay={300}
            offset={-90}
            duration={1500}>главная</Link>
          <Link
            onClick={this.showMobNav}
            className="prodacts"
            to="containerElement"
            spy={true}
            smooth={true}
            delay={300}
            offset={-90}
            duration={1500}>продукция</Link>
          <Link
            onClick={this.showMobNav}
            to="section3"
            spy={true}
            smooth={true}
            delay={300}
            offset={-90}

            duration={1500}>
            о компании
          </Link>
          <Link
            onClick={this.showMobNav}
            to="section4"
            spy={true}
            smooth={true}
            delay={300}
            offset={-90}

            duration={1500}
          >вопрос ответ</Link>
          <Link
            onClick={this.showMobNav}
            to="section5"
            spy={true}
            smooth={true}
            delay={300}
            offset={-90}

            duration={1500}
          >контакты
          </Link>
          <a href="https://agro-foto.ru/" target='_blank' rel="noopener noreferrer">слайдотека агро-фото</a>
          <a href="http://gallery.tekpolska.pl/" target='_blank' rel="noopener noreferrer">слайдотека ТЕК</a>
        </div>
      </>
    )
  }
}

export default Nav;
