import React from 'react';
import Pack1 from "../img/reclam/rec-1.webp";
import Pack2 from "../img/reclam/rec-2.webp";
import Pack3 from "../img/reclam/rec-3.webp";
import Pack4 from "../img/reclam/rec-4.webp";


const classSlide = ['smol1', 'smol2', 'big', 'smol3', 'smol4'];

class Promotional extends React.Component {
    constructor() {
        super();
        this.slidemarc = React.createRef();
        this.bg = React.createRef();
        this.text = React.createRef();
        this.LeftSatrtSlider = this.LeftSatrtSlider.bind(this);
        this.CloseText = this.CloseText.bind(this);
        this.OpenText = this.OpenText.bind(this);
    }

    componentDidMount() {
        let slides = this.slidemarc.current.children;
        classSlide.push(classSlide.shift());
        for (let i = 0; i < classSlide.length; i++) {
            slides[i].classList.value = classSlide[i];
        }
        this.timeoutSlideMarket = setInterval(() => {
            this.LeftSatrtSlider();
        }, 5000);

    }

    LeftSatrtSlider() {
        let LeftSatrt = [];
        let slides = this.slidemarc.current.children;
        for (let el of slides) {
            LeftSatrt.push(el.classList[0]);
        }
        LeftSatrt.push(LeftSatrt.shift());
        for (let i = 0; i < LeftSatrt.length; i++) {
            slides[i].classList.value = LeftSatrt[i];
        }

    }
    OpenText() {

        this.bg.current.classList.add("bg-text-active");
        this.text.current.classList.add("text-desription-box-active");
    }
    CloseText() {

        this.bg.current.classList.remove("bg-text-active");
        this.text.current.classList.remove("text-desription-box-active");
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutSlideMarket);
    }

    render() {

        return (
            <>

                <div className='page'>
                    <button className='left' onMouseEnter={() => clearTimeout(this.timeoutSlideMarket)} onClick={this.LeftSatrtSlider}>

                    </button>
                    <div className='slide-box' ref={this.slidemarc}>

                        <div className='big'  >
                            <img src={Pack1} alt="" />
                            <div className='description'>
                                <p>Рекламная продукция включает в себя изготовление плакатов или каталогов по Вашем запросу. Возможно выполнить любые размеры</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol1'  >
                            <img src={Pack3} alt="" />
                            <div className='description'>
                                <p>Рекламная продукция включает в себя изготовление плакатов или каталогов по Вашем запросу. Возможно выполнить любые размеры</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol2'  >
                            <img src={Pack2} alt="" />
                            <div className='description'>
                                <p>Рекламная продукция включает в себя изготовление плакатов или каталогов по Вашем запросу. Возможно выполнить любые размеры</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol3'  >
                            <img src={Pack1} alt="" />
                            <div className='description'>
                                <p>Рекламная продукция включает в себя изготовление плакатов или каталогов по Вашем запросу. Возможно выполнить любые размеры</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol4'  >
                            <img src={Pack4} alt="" />
                            <div className='description'>
                                <p>Рекламная продукция включает в себя изготовление плакатов или каталогов по Вашем запросу. Возможно выполнить любые размеры</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>


                    </div>
                    <button className='right' onClick={this.OpenText}>
                        <span className='romb'>подробнее</span>
                    </button>
                    <div className='bg-text ' ref={this.bg} onClick={this.CloseText}></div>
                    <div className='text-desription-box' ref={this.text}>
                        <button className='close' onClick={this.CloseText}></button>
                        <div className='text-desription'>
                            <div className='text-desription-scroll'>
                                <h3>Рекламная продукция</h3>
                                <p>Мы предлагаем множество видов продающих товаров: от календарей до рекламных плакатов и листовок.
                                    <br />    <strong> Продумаем до мелочей!</strong>
                                    <br />
                                    Печать каждого рекламного материала начинается с продумывания его концепции. Все знают, что ежедневно жители мегаполисов, гуляя по улицам, выбрасывают в мусорные баки тысячи буклетов, толком их не прочитав. Очевидно, что огромную роль играет идея, которая сможет в силу своей непривычности для потребителя удивить его и привлечь внимание, как и все необычное. Оригинальная идея должна отличаться не только своей самобытностью, но и отвечать потребностям потенциальных клиентов и быть предельно ясной.     Творческий коллектив компании «УПАКОВКА ДЛЯ СЕМЯН» знает все эти условия назубок и прилагает свои усилия, чтобы ваша реклама была максимально действенной и привлекательной.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Promotional;