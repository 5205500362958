import React from 'react';
import Video from '../Video/Video';
import bg from '../img/bg-bg.webp';
import { Helmet } from "react-helmet";
import { Link, Events, animateScroll as scroll } from "react-scroll";




class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            w: window.innerWidth,
            h: window.innerHeight,
            title: 'Производство упаковки для цветов и саженцев от компании',
            x: 50,
            y: 50,
        };
        this.rect = React.createRef();
        this.rectbox = React.createRef();
        this.corobki = React.createRef();
        this.poketi = React.createRef();
        this.reklama = React.createRef();
        this.galery = React.createRef();
        this.resizeRect = this.resizeRect.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickParent = this.handleClickParent.bind(this);

    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
            // console.log("start", arguments);
        });
        Events.scrollEvent.register('end', function () {
            // console.log("end", arguments);
        });

        window.addEventListener("resize", this.resizeRect);
        this.rectbox.current.style.background = 'url( ' + { bg } + ' ) center center / cover';
        this.corobki.current.classList.add('corobki');
        this.poketi.current.classList.add('poketi');
        this.reklama.current.classList.add('reklama');
        this.galery.current.classList.add('galery');

        if (window.innerHeight <= 520) {
            if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
                this.setState({ w: window.innerWidth + 70 });
            } else {
                this.setState({ w: (window.innerHeight + (window.innerHeight * .41) + 160) });
            }
        } else {
            if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
                this.setState({ w: window.innerWidth });
            } else {
                this.setState({ w: (window.innerHeight + (window.innerHeight * .41)) });
            }
        }
    }
    componentWillUnmount() {
        window.addEventListener("resize", null);
        let bg = document.querySelector('.rect-box');
        bg.style.opacity = 1;
    }
    resizeRect(w, h, event) {

        if (window.innerHeight <= 520) {
            if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
                this.setState({ w: window.innerWidth + 70 });
            } else {
                this.setState({ w: (window.innerHeight + (window.innerHeight * .41) + 160) });
            }
        } else {
            if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
                this.setState({ w: window.innerWidth });
            } else {
                this.setState({ w: (window.innerHeight + (window.innerHeight * .41)) });
            }
        }

    }
    scrollToTop() {
        scroll.scrollTo(60);
    }
    handleClick(e) {
        let text = e.target.innerText;
        document.querySelector('.prodacts').click();
        let links = document.querySelectorAll('.links');
        console.log(links)

        links.forEach(element => {
            element.classList.remove('active');
            if (text === element.innerText) {
                element.classList.add('active');
            }

        });
    }
    handleClickParent(e) {
        if (e.target.children[0]) {
            if (e.target.children[0].classList.contains('link-prod')) {
                e.target.children[0].click();
            }
            else if (e.target.children[0].children[0].classList.contains('link-prod')) {
                e.target.children[0].children[0].click();
            }
        }
    }


    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content='ООО " УПАКОВКА ДЛЯ СЕМЯН" - производственная компания, имеющая собственную полиграфию полного цикла, поэтому вы можете в кратчайшие сроки получить качественную упаковку с неповторимым дизайном. В нашей команде трудятся креативные люди, профессионалы своего дела.' />
                    <title>компания ООО «УПАКОВКА ДЛЯ СЕМЯН»</title>

                </Helmet>
                <div className='enter' >
                    <div className='mob-enter'>
                        <div className='mob-enter-box'>
                            <span className='Package-mob-enter'>
                                <Link
                                    onClick={this.handleClick}
                                    className='link-prod'
                                    to="Bags"
                                    smooth={true}
                                    delay={600}
                                    offset={-90}
                                    containerId="containerElement">
                                    <span>пакеты для семян</span>
                                </Link>
                            </span>
                            <span className='Cardboard-mob-enter'>
                                <Link
                                    onClick={this.handleClick}
                                    className='link-prod'
                                    to="Cardboard"
                                    smooth={true}
                                    delay={300}
                                    duration={1200}
                                    offset={-90}
                                    containerId="containerElement">
                                    <span> упаковка из картона</span>
                                </Link>
                            </span>

                            <span className='Promotional-mob-enter'>
                                <Link
                                    onClick={this.handleClick}
                                    className='link-prod'
                                    to="Promotional"
                                    smooth={true}
                                    delay={300}
                                    duration={1200}
                                    offset={-90}
                                    containerId="containerElement">
                                    <span>рекламная продукция</span>
                                </Link>
                            </span>
                            <span className='Plastic-mob-enter'>
                                <Link
                                    onClick={this.handleClick}
                                    className='link-prod link-prod-right'
                                    to="Plastic"
                                    spy={true}
                                    smooth={true}
                                    delay={300}
                                    duration={1200}
                                    offset={-90}
                                    containerId="containerElement">
                                    <span>пластиковые этикетки</span>
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className='rect-box' ref={this.rectbox} >
                        <div ref={this.rect} style={{ width: ((this.state.w / 5) - (this.state.w * .040)), height: ((this.state.w / 5) - (this.state.w * .040)) }} className='rect'>
                            <div className='intro-box'>
                                <div className='intro' style={{ width: (this.state.w / 3.5), height: (this.state.w / 3.5) }} >
                                </div>
                            </div>
                            <div onClick={this.handleClickParent} ref={this.reklama} className='seed rek' style={{ left: (this.state.w / 5) - (this.state.w * .034) }}>
                                <span className='pacet-box styleimport'>
                                    <Link
                                        onClick={this.handleClick}
                                        className='link-prod'
                                        to="Promotional"
                                        smooth={true}
                                        delay={300}
                                        duration={1200}
                                        offset={0}
                                        containerId="containerElement">
                                        рекламная продукция
                                    </Link>
                                </span>
                                <div className='seed slaid2' style={{ top: -(this.state.w / 5) + (this.state.w * .034) }}>

                                </div>
                                <div className='seed corobki2' ref={this.corobki}>
                                    <Link
                                        onClick={this.handleClick}
                                        className='link-prod'
                                        to="Promotional"
                                        smooth={true}
                                        delay={300}
                                        duration={1200}
                                        offset={0}
                                        containerId="containerElement">
                                        рекламная продукция
                                    </Link>
                                </div>
                            </div>
                            <div onClick={this.handleClickParent} className='seed' ref={this.poketi} style={{ left: -(this.state.w / 5) + (this.state.w * .034) }}>
                                <span className='pacet-box styleimport'>
                                    <Link
                                        onClick={this.handleClick}
                                        className='link-prod'
                                        to="Package"
                                        smooth={true}
                                        delay={300}
                                        duration={1200}
                                        offset={0}
                                        containerId="containerElement">
                                        пакеты для семян
                                    </Link>
                                </span>
                                <div className='seed slaid1' ref={this.corobki} style={{ bottom: -(this.state.w / 5) + (this.state.w * .034) }}></div>
                            </div>
                            <div onClick={this.handleClickParent} className='seed' ref={this.corobki} style={{ top: (this.state.w / 5) - (this.state.w * .034) }}>
                                <Link
                                    onClick={this.handleClick}
                                    className='link-prod'
                                    to="Cardboard"
                                    spy={true}
                                    smooth={true}
                                    delay={300}
                                    duration={1200}
                                    offset={0}
                                    containerId="containerElement">
                                    упаковка из картона
                                </Link>
                            </div>
                            <div onClick={this.handleClickParent} className='seed slaid1' style={{ bottom: (this.state.w / 5) - (this.state.w * .034) }}>
                                <div className='seed ' ref={this.galery}>
                                    <Link
                                        onClick={this.handleClick}
                                        className='link-prod link-prod-right'
                                        to="Plastic"
                                        spy={true}
                                        smooth={true}
                                        delay={300}
                                        duration={1200}
                                        offset={0}
                                        containerId="containerElement">
                                        пластиковые этикетки
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Video />
                </div >
            </>
        );
    }
}

export default Home;
