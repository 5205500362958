import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';




class YandexMaps extends React.Component {

  render() {
    return (
      <div className="YandexMaps" >

        <YMaps>

          <Map width="100%" height="100vh" defaultState={{
            center: [56.115022, 37.802598],
            zoom: 12,
            behaviors: ["disable('scrollZoom')"],
            controls: ['zoomControl']
          }}

            modules={['control.ZoomControl']}>
            <Placemark modules={['geoObject.addon.balloon']} defaultGeometry={[56.115022, 37.802598]} properties={{
              iconImageSize: [72, 72],
              balloonContentHeader: 'компания ООО «УПАКОВКА ДЛЯ СЕМЯН»',
              balloonContentBody: '<a href="tel:84955240609">+7 (495) 524-06-09</a><br> <a href="tel:89165153512">+7 (916) 515-35-12</a> <br> <a href="mailto:press@agro-pak.ru">press@agro-pak.ru</a>'
            }} />
          </Map>


        </YMaps>

      </div>
    );
  }
}

export default YandexMaps;
