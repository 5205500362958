import React from 'react';
import Pack1 from "../img/new/poketi/8-15-01.webp";
import Pack2 from "../img/new/poketi/8-15-02.webp";
import Pack3 from "../img/new/poketi/8-15-03.webp";
import Pack4 from "../img/new/poketi/8-15-04.webp";
import Pack5 from "../img/new/poketi/8-15-05.webp";
import Pack6 from "../img/new/poketi/8-15-06.webp";
import Pack7 from "../img/new/poketi/8-15-07.webp";
import Pack8 from "../img/new/poketi/9x13.webp";
import Pack9 from "../img/new/poketi/11,3х15,7.webp";

const classSlide = ['no-smol', 'no-smol', 'smol1', 'smol2', 'big', 'smol3', 'smol4'];

class Package extends React.Component {
    constructor() {
        super();
        this.slidemarc = React.createRef();
        this.bg = React.createRef();
        this.text = React.createRef();
        this.LeftSatrtSlider = this.LeftSatrtSlider.bind(this);

        this.CloseText = this.CloseText.bind(this);
        this.OpenText = this.OpenText.bind(this);
    }

    componentDidMount() {
        let slides = this.slidemarc.current.children;
        classSlide.push(classSlide.shift());
        for (let i = 0; i < classSlide.length; i++) {
            slides[i].classList.value = classSlide[i];
        }
        this.timeoutSlideMarket = setInterval(() => {
            this.LeftSatrtSlider();
        }, 5000);

    }

    LeftSatrtSlider() {
        let LeftSatrt = [];
        let slides = this.slidemarc.current.children;
        for (let el of slides) {
            LeftSatrt.push(el.classList[0]);
        }
        LeftSatrt.push(LeftSatrt.shift());
        for (let i = 0; i < LeftSatrt.length; i++) {
            slides[i].classList.value = LeftSatrt[i];
        }

    }
    OpenText() {
        clearTimeout(this.timeoutSlideMarket);
        this.bg.current.classList.add("bg-text-active");
        this.text.current.classList.add("text-desription-box-active");
    }
    CloseText() {

        this.bg.current.classList.remove("bg-text-active");
        this.text.current.classList.remove("text-desription-box-active");
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutSlideMarket);
    }


    render() {

        return (
            <>

                <div className='page'>
                    <button className='left' onMouseEnter={() => clearTimeout(this.timeoutSlideMarket)} onClick={this.LeftSatrtSlider}>

                    </button>

                    <div className='slide-box' ref={this.slidemarc}>
                        <div className='no-smol' >
                            <img src={Pack6} alt="" />
                            <div className='description'>
                                <p>Пакеты 7,5х11,5 без печати. Выполнены из офсетной белой бумаги и бурого крафта. Минимальная партия от 1000 штук.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='no-smol' >
                            <img src={Pack7} alt="" />
                            <div className='description'>
                                <p>Пакеты 7,5х11,5 без печати. Выполнены из офсетной белой бумаги и бурого крафта. Минимальная партия от 1000 штук.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='no-smol' >
                            <img src={Pack8} alt="" />
                            <div className='description'>
                                <p>Пакеты 9х13 изготавливаются из мелованной бумаги 115 и 130 гр в зависимости от веса фасовки. Данный размер выделяет нестандартная форма пакета, этот размер больше похож на Европейский стандарт пакетов. Минимальная партия 18000 штук, можно разместить до 18 видов по 1000 штук каждого.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='no-smol' >
                            <img src={Pack9} alt="" />
                            <div className='description'>
                                <p>Пакеты 11,3х15,7 изготавливаются из мелованной бумаги 130 гр для того чтобы в них можно было зафасовать больший вес. Многие фирмы используют их для фасовки ремонтного газона до 50 гр.
                                    <br />Минимальная партия 15000 штук, можно разместить до 15 видов по 1000 штук каждого.
                                </p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='big' onMouseEnter={() => clearTimeout(this.timeoutSlideMarket)} >
                            <img src={Pack1} alt="" />
                            <div className='description'>
                                <p>Пакеты 8х15 с уф лаком и без, печать 4+0.<br /> Выполнены из бумаги 115 гр.
                                    Минимальная партия для заказа 21000 штук. <br />
                                    Можно разместить до 21 вида по 1000 штук каждого.
                                </p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>

                        <div className='smol1'  >
                            <img src={Pack3} alt="" />
                            <div className='description'>
                                <p>Пакеты 8х15 с уф лаком и пантоном, тут использованы золотой и синий металлический пантоны. <br />
                                    Минимальная партия 105000 штук, можно разместить до 21 вида по 5000 штук каждого.
                                </p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol2'  >
                            <img src={Pack2} alt="" />
                            <div className='description'>
                                <p>Пакеты 8х15 с печатью 5+0 и уф лаком, использован пантон серебро.<br /> Пакет выполнен из мелованной бумаги 115 гр.<br />Минимальная партия 105000 штук, можно разместить до 21 вида по 5000 штук каждого.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol3'  >
                            <img src={Pack5} alt="" />
                            <div className='description'>
                                <p>Пакеты 7,5х11,5 из мелованной бумаги 115 гр с печатью 4+0. Минимальное количество для заказа 28000 штук. <br /> Можно разместить до 28 видов по 1000 штук каждого</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol4'  >
                            <img src={Pack4} alt="" />
                            <div className='description'>
                                <p>Пакеты 7,5х11,5 выполнены из офсетной бумаги с печатью 4+0. Офсетная бумага повсеместно используется для печати в принтерах. Плотность бумаги 80 гр, бумага плотная и этого вполне хватает чтобы семена не порвали ее. <br /> Минимальная партия для заказа 28000 штук, можно разместить до 28 видов по 1000 штук каждого</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>



                    </div>
                    <button className='right' onClick={this.OpenText}>
                        <span className='romb'>подробнее</span>
                    </button>
                    <div className='bg-text ' ref={this.bg} onClick={this.CloseText}></div>
                    <div className='text-desription-box' ref={this.text}>
                        <button className='close' onClick={this.CloseText}></button>
                        <div className='text-desription'>
                            <div className='text-desription-scroll'>
                                <h3>Пакеты для семян</h3>
                                <p>Семена - это натуральный продукт, который нужно хранить только в качественных упаковках. Плохая упаковка не сможет сохранить семена в надлежащем состоянии. При посадке вы просто не увидите желаемого результата. Качество упаковки напрямую влияет на всхожесть семян. Разновидность семян достаточно большая.</p>
                                <p>Высококачественная упаковка из бумаги Европейского производства с офсетной печатью 4+0, 5+0 и 5+1. Широкий выбор пантонных красок, чтобы сделать Ваш пакет уникальным. Покрытие ВД, Уф и комбинированными лаками для придания блеска и защиты. Также Вы можете заказать белые пакеты или пакеты из крафт-бумаги как более экономичный вариант. Упаковка из комбинированных материалов с печатью. Вы можете заказать уже сваренные по 3 сторонам пакеты или просто роль с печатью для дальнейшей фасовки.</p>
                                <p> Размеры пакетов из бумаги от 6х9 см до 14х17 см</p>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Package;