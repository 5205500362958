import React from 'react';
import Pack1 from "../img/carton/kor1.webp";
import Pack2 from "../img/carton/kor2.webp";
import Pack3 from "../img/carton/kor3.webp";
import Pack4 from "../img/carton/kor4.webp";

const classSlide = ['smol1', 'smol2', 'big', 'smol3', 'smol4'];

class Cardboard extends React.Component {
    constructor() {
        super();
        this.slidemarc = React.createRef();
        this.bg = React.createRef();
        this.text = React.createRef();
        this.LeftSatrtSlider = this.LeftSatrtSlider.bind(this);
        this.CloseText = this.CloseText.bind(this);
        this.OpenText = this.OpenText.bind(this);
    }

    componentDidMount() {
        let slides = this.slidemarc.current.children;
        classSlide.push(classSlide.shift());
        for (let i = 0; i < classSlide.length; i++) {
            slides[i].classList.value = classSlide[i];
        }
        this.timeoutSlideMarket = setInterval(() => {
            this.LeftSatrtSlider();
        }, 5000);

    }

    LeftSatrtSlider() {
        let LeftSatrt = [];
        let slides = this.slidemarc.current.children;
        for (let el of slides) {
            LeftSatrt.push(el.classList[0]);
        }
        LeftSatrt.push(LeftSatrt.shift());
        for (let i = 0; i < LeftSatrt.length; i++) {
            slides[i].classList.value = LeftSatrt[i];
        }

    }
    OpenText() {

        this.bg.current.classList.add("bg-text-active");
        this.text.current.classList.add("text-desription-box-active");
    }
    CloseText() {

        this.bg.current.classList.remove("bg-text-active");
        this.text.current.classList.remove("text-desription-box-active");
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutSlideMarket);
    }

    render() {

        return (
            <>

                <div className='page'>
                    <button className='left' onMouseEnter={() => clearTimeout(this.timeoutSlideMarket)} onClick={this.LeftSatrtSlider}>

                    </button>
                    <div className='slide-box' ref={this.slidemarc}>

                        <div className='big'  >
                            <img src={Pack1} alt="" />
                            <div className='description'>
                                <p>Коробки под саженцы разных размеров с печатью 4+0 и уф лаком. Мы можем изготовить для Вас любой размер коробки, а так же любые формы. Мы используем картон от 270 гр,  что позволяет сохранить конструкцию твердой и защитить саженец. <br /> Так же возможно использовать конгрев и тиснение фольгой.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol1'  >
                            <img src={Pack3} alt="" />
                            <div className='description'>
                                <p>Коробки под саженцы разных размеров с печатью 4+0 и уф лаком. Мы можем изготовить для Вас любой размер коробки, а так же любые формы. Мы используем картон от 270 гр,  что позволяет сохранить конструкцию твердой и защитить саженец. <br /> Так же возможно использовать конгрев и тиснение фольгой.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol2'  >
                            <img src={Pack2} alt="" />
                            <div className='description'>
                                <p>Коробки под саженцы разных размеров с печатью 4+0 и уф лаком. Мы можем изготовить для Вас любой размер коробки, а так же любые формы. Мы используем картон от 270 гр,  что позволяет сохранить конструкцию твердой и защитить саженец. <br /> Так же возможно использовать конгрев и тиснение фольгой.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol3'  >
                            <img src={Pack1} alt="" />
                            <div className='description'>
                                <p>Фигурные картонки. Данное изделие используется чтобы закрепить к картонке пакет. Можно выполнить любую форму и размер. Минимальное количество от 1000 листов.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>
                        <div className='smol4' >
                            <img src={Pack4} alt="" />
                            <div className='description'>
                                <p>Коробки под саженцы разных размеров с печатью 4+0 и уф лаком. Мы можем изготовить для Вас любой размер коробки, а так же любые формы. Мы используем картон от 270 гр,  что позволяет сохранить конструкцию твердой и защитить саженец. <br /> Так же возможно использовать конгрев и тиснение фольгой.</p>
                                <button onClick={this.OpenText} className='btn'>подробнее</button>
                            </div>
                        </div>


                    </div>
                    <button className='right' onClick={this.OpenText}>
                        <span className='romb'>подробнее</span>
                    </button>
                    <div className='bg-text ' ref={this.bg} onClick={this.CloseText}></div>
                    <div className='text-desription-box' ref={this.text}>
                        <button className='close' onClick={this.CloseText}></button>
                        <div className='text-desription'>
                            <div className='text-desription-scroll'>
                                <h3>Упаковка из картона</h3><p>Упаковка из картона сможет ярко выделить Ваш продукт на полке. Правильно подобранная плотность картона позволит сохранить и защитить Ваш продукт во время логистики и разгрузочно-погрузочных работ, а так же выкладки товара на полки. Упаковка из картона позволяет применить к ней множество процессов по послепечатной обработке. Конгрев, тиснение фольгой, выборочное лакирование - все это сделает Вашу упаковку неповторимой.</p> <p>Наши специалисты с удовольствием помогут Вам подобрать нужный размер и форму упаковки, а профессиональный штат дизайнеров разработает макет.</p>
                            </div></div>
                    </div>
                </div>
            </>
        );
    }
}

export default Cardboard;