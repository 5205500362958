import React, { Component } from "react";
import logo from "../logo.svg";
import { Link, animateScroll as scroll } from "react-scroll";



class Navbar extends Component {

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    let iter = true;
    let menu = 0;
    let off = 0;


    if (window.innerWidth >= 960) {

      document.addEventListener('wheel', function (event) {
        let delta = event.deltaY || event.detail || event.wheelDelta;
        if (delta > 5 && iter === true) {
          menu = document.querySelectorAll('.menu-top span .scrol');
          menu.forEach(element => {
            if (element.classList.contains("active")) {
              if (element.nextElementSibling) {
                if (element.nextElementSibling.classList.contains("scrol")) {
                  element.nextElementSibling.click();
                }
              }
            }
          });
          iter = false;
          setTimeout(function () { iter = true }, 1500)
        } if (delta < -3 && iter === true) {
          menu = document.querySelectorAll('.menu-top span .scrol');
          menu.forEach(element => {
            if (element.classList.contains("active")) {
              if (element.previousSibling) {
                if (element.previousSibling.classList.contains("scrol")) {
                  element.previousSibling.click();
                }
              }
            }
          });
          iter = false;
          setTimeout(function () { iter = true }, 1500)
        }
      });
    }

    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <a className='logo' href="/">
            <img src={logo} alt="логотип УДС" />
          </a>
          <div className='info_box'>
            <div className='info'>
              <a href='tel:+74965000381'>+7 (496) 500 03 81</a>
              <a href='tel:+79165153512'>+7 (916) 515 35 12</a>
              <a className='mail' href='mailto:'>press@agro-pak.ru</a>
            </div>
            <nav className='menu-top'>

              <span>

                <Link
                  className="scrol"
                  to="section1"
                  spy={true}
                  smooth={true}
                  delay={300}
                  offset={-90}
                  duration={600}>главная</Link>



                <Link
                  className="scrol prodacts"
                  to="containerElement"
                  spy={true}
                  smooth={true}
                  delay={300}
                  offset={-90}
                  duration={600}>продукция</Link>



                <Link
                  className="scrol"
                  to="section3"
                  spy={true}
                  smooth={true}
                  delay={300}
                  offset={-90}

                  duration={600}>
                  о компании
                </Link>


                <Link
                  className="scrol"
                  to="section4"
                  spy={true}
                  smooth={true}
                  delay={300}
                  offset={-90}

                  duration={600}
                >вопрос ответ</Link>



                <Link
                  className="scrol"
                  to="section5"
                  spy={true}
                  smooth={true}
                  delay={300}
                  offset={-90}

                  duration={600}
                >контакты
                </Link>


                <a href="https://agro-foto.ru/" rel="noreferrer" target={'_blank'} >слайдотека агро-фото</a>
                <a href="http://gallery.tekpolska.pl/" rel="noreferrer"  >слайдотека ТЕК</a>
              </span>
            </nav>
          </div>
        </div>
      </nav>
    );
  }
}
export default Navbar;
