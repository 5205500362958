import React from 'react';
import Sabsection from '../Components/Sabsection';

function Prodacts() {

    return (
        <>
            <div className='headh1'><h1>Продукция</h1></div>
            <Sabsection />
        </>
    );
}

export default Prodacts;