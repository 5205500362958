import React, { Component } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
import Home from './Page/Home';
import Prodacts from './Page/Prodacts';
import Faq from './Page/Faq';
import Compani from './Page/Compani';
import Сontacts from './Page/Сontacts';
import NavMob from './navMob/NavMob';

const question = require('./data/question.json');

class App extends Component {


  render() {
    return (
      <div className="App">
        <Navbar />

        <Section
          title="Section 1"
          subtitle={<Home />}
          dark={true}
          id="section1"
        />

        <Section
          title="Section 2"
          subtitle={<Prodacts />}
          dark={false}
          id="section2"
        />
        <Section
          title="Section 3"
          subtitle={<Compani />}
          dark={true}
          id="section3"
        />
        <Section
          title="Section 4"
          subtitle={<Faq fag={question} />}
          dark={false}
          id="section4"
        />
        <Section
          title="Section 5"
          subtitle={<Сontacts />}
          dark={true}
          id="section5"
        />
        <NavMob />
      </div>

    );
  }
}

export default App;
