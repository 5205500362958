
import React, { Component } from "react";
import Package from "../Page/Package";
import Cardboard from "../Page/Cardboard";
import Promotional from "../Page/Promotional";
import Plastic from "../Page/Plastic";
import { Link, Element, animateScroll as scroll } from "react-scroll";


class Sabsection extends Component {

  constructor() {
    super();

    this.scrollTo = this.scrollTo.bind(this);
    this.ActiveSabmenuCklik = this.ActiveSabmenuCklik.bind(this);
  }
  scrollTo() {
    scroll.scrollTo(window.innerHeight);
  }
  ActiveSabmenuCklik(element) {
    this.scrollTo();
    let links = document.querySelectorAll('.links');
    links.forEach(element => {
      element.classList.remove('active');
    });

    element.target.classList.toggle('active');
  }

  render() {


    return (
      <>
        <nav className='sab-menu'>
          <ul>
            <li >
              <Link
                onClick={this.ActiveSabmenuCklik}
                className="links"
                to="Bags"
                activeClass="active"
                smooth={true}
                delay={300}
                duration={1200}
                offset={0}
                containerId="containerElement">пакеты для семян</Link>
            </li>
            <li >

              <Link
                onClick={this.ActiveSabmenuCklik}
                activeClass="active"
                className="links"
                to="Cardboard"
                smooth={true}
                delay={300}
                duration={1200}
                offset={0}
                containerId="containerElement">
                упаковка из картона
              </Link>

            </li>
            <li>
              <Link
                onClick={this.ActiveSabmenuCklik}
                activeClass="active"
                className="links"
                to="Promotional"
                smooth={true}
                delay={300}
                duration={1200}
                offset={0}
                containerId="containerElement">
                рекламная продукция
              </Link>
            </li>
            <li>
              <Link
                onClick={this.ActiveSabmenuCklik}
                activeClass="active"
                className="links"
                to="Plastic"
                smooth={true}
                delay={300}
                duration={1200}
                offset={0}
                containerId="containerElement">
                пластиковые этикетки
              </Link>
            </li>
          </ul>
        </nav>

        <Element name="Prodacts" className="box-prodact" id="containerElement">
          <Element name="Bags" className="Bags" id="Bags">
            <Package />
          </Element>
          <Element name="Cardboard" className="Cardboard" id="Cardboard">
            <Cardboard />
          </Element>
          <Element name="Promotional" className="Advertisement" id="Advertisement">
            <Promotional />
          </Element>
          <Element name="Plastic" className="Plastic" id="Plastic">
            <Plastic />
          </Element>
        </Element>
      </>
    );
  }
}
export default Sabsection;